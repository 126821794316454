.Course-group {
  align-items: center;
  display: flex;
  position: relative;


  .Course-item:not(.Dropdown-item),
  .Dropdown-item > div {
    margin-right: .5rem;
    width: 100%;
    padding-bottom: 5px;
  }

  .Dropdown-item {
    margin-right: .5rem;
    width: 100%;
  }

  .add-course {
    margin-bottom: 1.5rem;
  }

  .error-label {
    line-height: 1;
    margin-top: -3px;
  }

  .Course-item--remove {
    opacity: 1 !important;
    transition: opacity .2s;
    position: absolute;
    right: 0;
    bottom: 5px;
    background-color: #fff;
    border-radius: 0;
    color: #53c6fd !important;
  }

  .Course-item--remove span {
    opacity: 1;
  }

  .Course-item--remove:hover span {
    opacity: 1;
  }

  .Course-item label {
    color: rgba(0, 0, 0, .4);
  }

  .Course-item input {
    border-bottom-color: rgba(0, 0, 0, .3);
  }

  .Course-item input[type="number"]::-webkit-outer-spin-button,
  .Course-item input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .Dropdown-item li {
    margin: 0;
  }

  @media (max-width: 420px) {
    .Course-item label,
    .Course-item input,
    .Dropdown-item > div {
      font-size: 13px;
    }
  }
}
